import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Radio, Home, Info, Music, Headphones, Phone, Menu, X, Trophy, Code, Shield } from 'lucide-react';
import { scrollToElement } from '../utils/scroll';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutMenuOpen, setIsAboutMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navigation = [
    { name: 'Home', href: '/', icon: Home },
    {
      name: 'Sobre',
      href: '/sobre',
      icon: Info,
      submenu: [
        { name: 'Nossa História', href: '/sobre', icon: Info },
        { name: 'Política de Privacidade', href: '/privacidade', icon: Shield }
      ]
    },
    { name: 'Pedir Música', href: '/#music-request', icon: Music },
    { name: 'Ranking', href: '/#ranking', icon: Trophy },
    { name: 'Apps', href: '/apps', icon: Headphones },
    { name: 'Contato', href: '/contato', icon: Phone },
    { name: 'Adicionar em seu Site', href: '/embed', icon: Code },
  ];

  const handleNavigation = (href: string) => {
    setIsMenuOpen(false);
    setIsAboutMenuOpen(false);
    
    if (href.startsWith('/#')) {
      const elementId = href.substring(2);
      if (location.pathname === '/') {
        scrollToElement(elementId);
      } else {
        navigate('/', { replace: true });
        setTimeout(() => scrollToElement(elementId), 100);
      }
    } else {
      navigate(href);
    }
  };

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between h-14">
          <div className="flex">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0 flex items-center">
              <Radio className="h-6 w-6 text-brown-800" />
              <span className="ml-2 text-lg font-bold text-gray-900">Rádio Tok</span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden sm:ml-6 sm:flex sm:space-x-6">
              {navigation.map((item) => {
                const Icon = item.icon;
                if (item.submenu) {
                  return (
                    <div key={item.name} className="relative">
                      <button
                        onClick={() => setIsAboutMenuOpen(!isAboutMenuOpen)}
                        className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                          isActive(item.href)
                            ? 'border-brown-800 text-brown-800'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        }`}
                      >
                        <Icon className="h-4 w-4 mr-1.5" />
                        {item.name}
                      </button>
                      {isAboutMenuOpen && (
                        <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            {item.submenu.map((subitem) => {
                              const SubIcon = subitem.icon;
                              return (
                                <button
                                  key={subitem.name}
                                  onClick={() => handleNavigation(subitem.href)}
                                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                  <SubIcon className="h-4 w-4 mr-2" />
                                  {subitem.name}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
                return (
                  <button
                    key={item.name}
                    onClick={() => handleNavigation(item.href)}
                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                      isActive(item.href)
                        ? 'border-brown-800 text-brown-800'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    }`}
                  >
                    <Icon className="h-4 w-4 mr-1.5" />
                    {item.name}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              aria-expanded="false"
            >
              {isMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {navigation.map((item) => {
              const Icon = item.icon;
              if (item.submenu) {
                return (
                  <div key={item.name}>
                    <button
                      onClick={() => setIsAboutMenuOpen(!isAboutMenuOpen)}
                      className={`flex items-center w-full px-3 py-2 text-base font-medium ${
                        isActive(item.href)
                          ? 'bg-brown-50 border-l-4 border-brown-800 text-brown-800'
                          : 'text-gray-600 hover:bg-gray-50 hover:border-l-4 hover:border-gray-300'
                      }`}
                    >
                      <Icon className="h-5 w-5 mr-3" />
                      {item.name}
                    </button>
                    {isAboutMenuOpen && (
                      <div className="pl-8 space-y-1">
                        {item.submenu.map((subitem) => {
                          const SubIcon = subitem.icon;
                          return (
                            <button
                              key={subitem.name}
                              onClick={() => handleNavigation(subitem.href)}
                              className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
                            >
                              <SubIcon className="h-4 w-4 mr-2" />
                              {subitem.name}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              }
              return (
                <button
                  key={item.name}
                  onClick={() => handleNavigation(item.href)}
                  className={`flex items-center w-full px-3 py-2 text-base font-medium ${
                    isActive(item.href)
                      ? 'bg-brown-50 border-l-4 border-brown-800 text-brown-800'
                      : 'text-gray-600 hover:bg-gray-50 hover:border-l-4 hover:border-gray-300'
                  }`}
                >
                  <Icon className="h-5 w-5 mr-3" />
                  {item.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </nav>
  );
}