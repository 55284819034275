import React, { useState } from 'react';
import { Music, MessageCircle, Send } from 'lucide-react';
import { supabase } from '../lib/supabase';

const WHATSAPP_NUMBER = '5519983829517';
const DEFAULT_MESSAGE = 'Olá! Gostaria de pedir uma música.';

export default function MusicRequest() {
  const [formData, setFormData] = useState({
    name: '',
    song: '',
    artist: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(DEFAULT_MESSAGE)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');

    try {
      const { error } = await supabase
        .from('music_requests')
        .insert([formData]);

      if (error) throw error;

      setStatus('success');
      setFormData({ name: '', song: '', artist: '', message: '' });
      setTimeout(() => setStatus('idle'), 3000);
    } catch (err) {
      console.error('Error submitting request:', err);
      setStatus('error');
    }
  };

  return (
    <div id="music-request" className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-6">
        <Music className="text-red-600" size={24} />
        <h2 className="text-2xl font-bold">Peça sua Música</h2>
      </div>

      <div className="space-y-6">
        {/* WhatsApp Button */}
        <div className="text-center">
          <p className="text-gray-600 mb-4">
            Faça seu pedido de música diretamente pelo WhatsApp! 
            Nossos DJs estão prontos para atender você.
          </p>

          <button
            onClick={handleWhatsAppClick}
            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <MessageCircle size={24} className="mr-2" />
            Pedir Música no WhatsApp
          </button>

          <p className="text-sm text-gray-500 mt-2">
            Atendimento 24 horas para pedidos de música
          </p>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">ou</span>
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {status === 'success' && (
            <div className="bg-green-50 text-green-800 p-4 rounded-md">
              Pedido enviado com sucesso! Aguarde a aprovação.
            </div>
          )}
          {status === 'error' && (
            <div className="bg-red-50 text-red-800 p-4 rounded-md">
              Erro ao enviar pedido. Tente novamente.
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Seu Nome</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Nome da Música</label>
            <input
              type="text"
              value={formData.song}
              onChange={(e) => setFormData({ ...formData, song: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Artista</label>
            <input
              type="text"
              value={formData.artist}
              onChange={(e) => setFormData({ ...formData, artist: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mensagem (Opcional)</label>
            <textarea
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              rows={3}
            />
          </div>

          <button
            type="submit"
            disabled={status === 'loading'}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
          >
            <Send size={16} className="mr-2" />
            {status === 'loading' ? 'Enviando...' : 'Enviar Pedido'}
          </button>
        </form>
      </div>
    </div>
  );
}