import React from 'react';
import { SEO } from '../utils/seo';
import { Smartphone, Radio, Download, MessageCircle, Bell } from 'lucide-react';

export default function Apps() {
  return (
    <>
      <SEO 
        title="Aplicativos"
        description="Baixe os aplicativos da Rádio Tok para iOS, Android e Alexa. Leve a melhor programação sertaneja com você para qualquer lugar."
        keywords="app rádio tok, aplicativo sertanejo, alexa skill, rádio no celular"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Nossos Aplicativos</h1>
            <p className="text-xl text-gray-600">
              Leve a Rádio Tok com você para qualquer lugar
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
            {/* iOS App */}
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
                </svg>
              </div>
              <h2 className="text-xl font-semibold mb-4">App iOS</h2>
              <p className="text-gray-600 mb-6">
                Disponível para iPhone, iPad e iPod Touch. Compatível com iOS 12 ou superior.
              </p>
              <a 
                href="https://apps.apple.com/br/app/id6738800188" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img 
                  src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg" 
                  alt="Baixar na App Store" 
                  className="h-12" 
                />
              </a>
            </div>

            {/* Android App */}
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Smartphone className="w-8 h-8 text-green-600" />
              </div>
              <h2 className="text-xl font-semibold mb-4">App Android</h2>
              <p className="text-gray-600 mb-6">
                Disponível para smartphones e tablets Android. Requer Android 6.0 ou superior.
              </p>
              <a 
                href="https://play.google.com/store/apps/details?id=com.radiose5" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img 
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" 
                  alt="Disponível no Google Play" 
                  className="h-12" 
                />
              </a>
            </div>

            {/* Alexa Skill */}
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Radio className="w-8 h-8 text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold mb-4">Alexa Skill</h2>
              <p className="text-gray-600 mb-6">
                Ouça a Rádio Tok em qualquer dispositivo com Alexa. Basta dizer "Alexa, abrir Rádio Tok".
              </p>
              <a 
                href="https://www.amazon.com.br/554-887-188-93-R%C3%A1dio-Tok/dp/B0DNR4P15F" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center h-12 px-6 bg-[#00CAFF] text-white rounded-lg hover:bg-[#00A8FF] transition-colors"
              >
                <svg className="w-8 h-8 mr-2" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12.24 0c-.71.01-1.4.12-2.07.31A5.99 5.99 0 0 0 6.11 4.5c-.19.67-.3 1.36-.31 2.07v10.86c.01.71.12 1.4.31 2.07a5.99 5.99 0 0 0 4.06 4.19c.67.19 1.36.3 2.07.31h10.86c.71-.01 1.4-.12 2.07-.31a5.99 5.99 0 0 0 4.19-4.06c.19-.67.3-1.36.31-2.07V6.57c-.01-.71-.12-1.4-.31-2.07a5.99 5.99 0 0 0-4.06-4.19c-.67-.19-1.36-.3-2.07-.31H12.24zM12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
                </svg>
                Habilitar na Alexa
              </a>
            </div>
          </div>

          {/* Features Section */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-6 text-center">Recursos dos Apps</h2>
            
            <div className="grid gap-6 md:grid-cols-2">
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Radio className="text-red-600" size={24} />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Streaming em Alta Qualidade</h3>
                  <p className="text-gray-600">
                    Áudio em alta definição para a melhor experiência musical.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Download className="text-blue-600" size={24} />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Modo Offline</h3>
                  <p className="text-gray-600">
                    Baixe suas músicas favoritas para ouvir sem internet.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <MessageCircle className="text-green-600" size={24} />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Pedidos de Música</h3>
                  <p className="text-gray-600">
                    Faça seus pedidos diretamente pelo app, 24 horas por dia.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Bell className="text-purple-600" size={24} />
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Notificações</h3>
                  <p className="text-gray-600">
                    Receba alertas sobre novidades, promoções e sua música tocando.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}