import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

type News = {
  id: string;
  title: string;
  content: string;
  slug: string;
  image_url: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  featured: boolean;
  published: boolean;
  created_at: string;
  updated_at: string;
};

export function useNews() {
  const [featuredNews, setFeaturedNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadFeaturedNews();
  }, []);

  async function loadFeaturedNews() {
    try {
      const { data, error: supabaseError } = await supabase
        .from('news')
        .select('*')
        .eq('published', true)
        .eq('featured', true)
        .order('created_at', { ascending: false })
        .limit(3);

      if (supabaseError) throw supabaseError;

      setFeaturedNews(data || []);
    } catch (err: any) {
      console.error('Error loading news:', err);
      setError('Não foi possível carregar as notícias no momento.');
    } finally {
      setLoading(false);
    }
  }

  return { featuredNews, loading, error };
}