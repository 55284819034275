class AudioStreamService {
  private audio: HTMLAudioElement;
  private retryCount = 0;
  private maxRetries = 3;
  private retryTimeout: number | null = null;
  private readonly STREAM_URL = 'https://cast2.hoost.com.br:20088/stream';

  constructor() {
    this.audio = new Audio(this.STREAM_URL);
    this.audio.preload = 'none';
  }

  public async play() {
    try {
      await this.audio.play();
    } catch (error) {
      console.error('Audio error:', error);
      throw new Error('Não foi possível iniciar a reprodução');
    }
  }

  public pause() {
    this.audio.pause();
  }

  public setVolume(value: number) {
    this.audio.volume = Math.max(0, Math.min(1, value));
  }

  public setMuted(value: boolean) {
    this.audio.muted = value;
  }

  public isPlaying() {
    return !this.audio.paused;
  }

  public cleanup() {
    this.audio.pause();
    this.audio.src = '';
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
  }
}

export const audioStream = new AudioStreamService();