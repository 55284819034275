import React from 'react';

export default function AppDownload() {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8">Baixe Nossos Aplicativos</h2>
        <div className="flex flex-wrap justify-center gap-6">
          <a 
            href="https://apps.apple.com/br/app/id6738800188" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block"
          >
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg" 
              alt="Baixar na App Store" 
              className="h-12" 
            />
          </a>
          <a 
            href="https://play.google.com/store/apps/details?id=com.radiose5" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block"
          >
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" 
              alt="Disponível no Google Play" 
              className="h-12" 
            />
          </a>
          <a 
            href="https://www.amazon.com.br/554-887-188-93-R%C3%A1dio-Tok/dp/B0DNR4P15F" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center h-12 px-6 bg-[#00CAFF] text-white rounded-lg hover:bg-[#00A8FF] transition-colors"
          >
            <svg className="w-8 h-8 mr-2" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12.24 0c-.71.01-1.4.12-2.07.31A5.99 5.99 0 0 0 6.11 4.5c-.19.67-.3 1.36-.31 2.07v10.86c.01.71.12 1.4.31 2.07a5.99 5.99 0 0 0 4.06 4.19c.67.19 1.36.3 2.07.31h10.86c.71-.01 1.4-.12 2.07-.31a5.99 5.99 0 0 0 4.19-4.06c.19-.67.3-1.36.31-2.07V6.57c-.01-.71-.12-1.4-.31-2.07a5.99 5.99 0 0 0-4.06-4.19c-.67-.19-1.36-.3-2.07-.31H12.24zM12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
            </svg>
            Disponível na Alexa
          </a>
        </div>
      </div>
    </div>
  );
}