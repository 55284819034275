import React from 'react';
import { SEO } from '../utils/seo';
import { MessageCircle, Mail, MapPin } from 'lucide-react';

export default function Contact() {
  const WHATSAPP_NUMBER = '5519983829517';

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${WHATSAPP_NUMBER}`, '_blank');
  };

  return (
    <>
      <SEO 
        title="Contato"
        description="Entre em contato com a Rádio Tok. Fale conosco por WhatsApp ou envie sua mensagem através do formulário. Atendimento 24 horas."
        keywords="contato rádio tok, fale conosco, whatsapp rádio, atendimento"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Fale Conosco</h1>
            <p className="text-xl text-gray-600">
              Estamos sempre prontos para te atender
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2">
            {/* Contact Info */}
            <div className="space-y-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-6">Informações de Contato</h2>
                
                <div className="space-y-4">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
                      <MessageCircle className="text-green-600" size={20} />
                    </div>
                    <div>
                      <p className="font-medium">WhatsApp</p>
                      <button 
                        onClick={handleWhatsAppClick}
                        className="text-green-600 hover:text-green-700"
                      >
                        (19) 98382-9517
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                      <Mail className="text-blue-600" size={20} />
                    </div>
                    <div>
                      <p className="font-medium">E-mail</p>
                      <a 
                        href="mailto:contatoradiotok@hotmail.com"
                        className="text-blue-600 hover:text-blue-700"
                      >
                        contatoradiotok@hotmail.com
                      </a>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center">
                      <MapPin className="text-purple-600" size={20} />
                    </div>
                    <div>
                      <p className="font-medium">Endereço</p>
                      <address className="not-italic text-gray-600">
                        Rua São João, 201 - Vila Dainese<br />
                        Santa Bárbara d'Oeste - SP<br />
                        CEP: 13455-115
                      </address>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4">Horário de Atendimento</h2>
                <div className="space-y-2 text-gray-600">
                  <p>Segunda a Sexta: 8h às 18h</p>
                  <p>Sábado: 9h às 13h</p>
                  <p className="text-sm mt-4">
                    * Pedidos de música são atendidos 24h pelo WhatsApp
                  </p>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-6">Envie sua Mensagem</h2>
              
              <form className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Assunto
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Mensagem
                  </label>
                  <textarea
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Enviar Mensagem
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}