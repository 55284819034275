import React from 'react';
import { Facebook, Twitter, Instagram } from 'lucide-react';

export default function SocialLinks() {
  return (
    <div className="flex gap-6">
      <a 
        href="http://facebook.com/radiotokoficial" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-white hover:text-red-500 transition-colors"
        aria-label="Facebook"
      >
        <Facebook size={24} />
      </a>
      <a 
        href="http://x.com/radiotok" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-white hover:text-red-500 transition-colors"
        aria-label="Twitter"
      >
        <Twitter size={24} />
      </a>
      <a 
        href="https://www.instagram.com/radiotokoficial" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-white hover:text-red-500 transition-colors"
        aria-label="Instagram"
      >
        <Instagram size={24} />
      </a>
    </div>
  );
}