import React from 'react';
import { Play, Pause, Volume2, VolumeX, AlertCircle } from 'lucide-react';
import StreamInfo from './StreamInfo';
import { useAudioPlayer } from '../hooks/useAudioPlayer';

export default function AudioPlayer() {
  const {
    isPlaying,
    isMuted,
    volume,
    error,
    togglePlay,
    handleVolumeChange,
    toggleMute
  } = useAudioPlayer();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-3 z-50">
      <div className="max-w-6xl mx-auto flex items-center justify-between">
        <div className="flex items-center gap-3">
          <button
            onClick={togglePlay}
            className="bg-brown-800 hover:bg-brown-900 text-white p-2.5 rounded-full transition-colors"
            aria-label={isPlaying ? 'Pausar' : 'Tocar'}
          >
            {isPlaying ? <Pause size={20} /> : <Play size={20} />}
          </button>
          <div className="flex items-center gap-2">
            <button 
              onClick={toggleMute} 
              className="text-gray-600 hover:text-gray-800 transition-colors" 
              aria-label={isMuted ? 'Ativar som' : 'Mutar'}
            >
              {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
              className="w-20 accent-brown-800"
              aria-label="Volume"
            />
          </div>
        </div>

        <StreamInfo />

        {error && (
          <div className="fixed top-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded-lg flex items-center gap-2 shadow-lg">
            <AlertCircle size={20} />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
}