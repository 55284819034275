import { useEffect } from 'react';
import { useSettings } from './useSettings';
import { generateRadioStationSchema, insertSchema } from '../utils/schema';

export function useSchema() {
  const { settings } = useSettings();

  useEffect(() => {
    if (settings) {
      const schema = generateRadioStationSchema(settings);
      insertSchema(schema);
    }
  }, [settings]);
}