import React, { useState, useEffect } from 'react';
import { Settings as SettingsIcon, Image, Save } from 'lucide-react';
import { supabase } from '../../lib/supabase';

type SiteSettings = {
  hero_image: string;
  site_logo: string;
};

export default function Settings() {
  const [settings, setSettings] = useState<SiteSettings>({
    hero_image: '',
    site_logo: ''
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      const { data, error } = await supabase
        .from('site_settings')
        .select('*')
        .single();

      if (error) throw error;
      if (data) {
        setSettings(data);
      }
    } catch (err) {
      console.error('Error loading settings:', err);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setSaving(true);
    setMessage(null);

    try {
      const { error } = await supabase
        .from('site_settings')
        .upsert({
          id: 1, // Single row for settings
          ...settings,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
      setMessage({ type: 'success', text: 'Configurações salvas com sucesso!' });
    } catch (err) {
      console.error('Error saving settings:', err);
      setMessage({ type: 'error', text: 'Erro ao salvar as configurações.' });
    } finally {
      setSaving(false);
    }
  }

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <SettingsIcon className="text-red-600" />
        <h2 className="text-2xl font-bold">Configurações do Site</h2>
      </div>

      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md space-y-6">
        {message && (
          <div className={`p-4 rounded-md ${
            message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
          }`}>
            {message.text}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Logo do Site
          </label>
          <div className="space-y-3">
            {settings.site_logo && (
              <img
                src={settings.site_logo}
                alt="Logo do site"
                className="h-12 object-contain"
              />
            )}
            <input
              type="url"
              value={settings.site_logo}
              onChange={(e) => setSettings({ ...settings, site_logo: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              placeholder="https://exemplo.com/logo.png"
            />
            <p className="text-sm text-gray-500">
              URL da imagem da logo que será exibida no topo do site
            </p>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Imagem de Destaque da Home
          </label>
          <div className="space-y-3">
            {settings.hero_image && (
              <img
                src={settings.hero_image}
                alt="Imagem de destaque"
                className="w-full h-48 object-cover rounded-lg"
              />
            )}
            <input
              type="url"
              value={settings.hero_image}
              onChange={(e) => setSettings({ ...settings, hero_image: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              placeholder="https://exemplo.com/imagem.jpg"
            />
            <p className="text-sm text-gray-500">
              URL da imagem que será exibida no topo da página inicial
            </p>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
          >
            <Save className="h-4 w-4 mr-2" />
            {saving ? 'Salvando...' : 'Salvar Configurações'}
          </button>
        </div>
      </form>
    </div>
  );
}