import React, { useState, useEffect } from 'react';
import { Info, Save } from 'lucide-react';
import { supabase } from '../../lib/supabase';

type AboutContent = {
  id: string;
  title: string;
  content: string;
  updated_at: string;
};

export default function About() {
  const [content, setContent] = useState<AboutContent>({
    id: '1',
    title: 'Sobre a Rádio Tok',
    content: '',
    updated_at: new Date().toISOString()
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

  useEffect(() => {
    loadContent();
  }, []);

  async function loadContent() {
    try {
      const { data, error } = await supabase
        .from('about_content')
        .select('*')
        .single();

      if (error) throw error;
      if (data) {
        setContent(data);
      }
    } catch (err) {
      console.error('Error loading about content:', err);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setSaving(true);
    setMessage(null);

    try {
      const { error } = await supabase
        .from('about_content')
        .upsert({
          id: content.id,
          title: content.title,
          content: content.content,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
      setMessage({ type: 'success', text: 'Conteúdo salvo com sucesso!' });
    } catch (err) {
      console.error('Error saving about content:', err);
      setMessage({ type: 'error', text: 'Erro ao salvar o conteúdo.' });
    } finally {
      setSaving(false);
    }
  }

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Info className="text-red-600" size={24} />
        <h2 className="text-2xl font-bold">Editar Página Sobre</h2>
      </div>

      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md space-y-6">
        {message && (
          <div className={`p-4 rounded-md ${
            message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
          }`}>
            {message.text}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Título da Página
          </label>
          <input
            type="text"
            value={content.title}
            onChange={(e) => setContent({ ...content, title: e.target.value })}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Conteúdo
          </label>
          <textarea
            value={content.content}
            onChange={(e) => setContent({ ...content, content: e.target.value })}
            rows={15}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
            required
          />
          <p className="mt-2 text-sm text-gray-500">
            Dica: Use marcação Markdown para formatação. Exemplo: **texto em negrito**, *itálico*, ## título
          </p>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
          >
            <Save className="h-4 w-4 mr-2" />
            {saving ? 'Salvando...' : 'Salvar Alterações'}
          </button>
        </div>
      </form>
    </div>
  );
}