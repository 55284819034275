const FALLBACK_IMAGES = {
  hero: [
    'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?w=1600&q=80',
    'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?w=1600&q=80',
    'https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?w=1600&q=80'
  ],
  default: 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?w=1600&q=80'
};

export function getRandomFallbackImage(type: keyof typeof FALLBACK_IMAGES = 'default'): string {
  const images = FALLBACK_IMAGES[type];
  if (Array.isArray(images)) {
    return images[Math.floor(Math.random() * images.length)];
  }
  return images;
}

export function preloadImage(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = () => reject(new Error('Failed to load image'));
  });
}