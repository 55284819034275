import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

type SiteSettings = {
  hero_image: string;
  site_logo: string;
};

export function useSettings() {
  const [settings, setSettings] = useState<SiteSettings>({
    hero_image: '',
    site_logo: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      const { data, error: supabaseError } = await supabase
        .from('site_settings')
        .select('*')
        .single();

      if (supabaseError) throw supabaseError;
      if (data) {
        setSettings(data);
      }
    } catch (err: any) {
      console.error('Error loading settings:', err);
      setError('Não foi possível carregar as configurações do site.');
    } finally {
      setLoading(false);
    }
  }

  return { settings, loading, error };
}