import React, { useEffect } from 'react';
import { History } from 'lucide-react';

declare global {
  interface Window {
    CC_recenttracks_list?: any;
  }
}

export default function RecentTracks() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cast2.hoost.com.br:2199/system/recenttracks.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-6">
        <History className="text-blue-600" size={24} />
        <h2 className="text-2xl font-bold">Músicas Recentes</h2>
      </div>
      <div 
        className="cc_recenttracks_list space-y-3" 
        data-username="radios18"
      >
        Carregando...
      </div>
    </div>
  );
}