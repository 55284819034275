import React from 'react';
import { Share2, Facebook, Twitter, MessageCircle, Link as LinkIcon } from 'lucide-react';

type SocialShareProps = {
  url: string;
  title: string;
};

export default function SocialShare({ url, title }: SocialShareProps) {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const shareLinks = [
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      color: 'bg-[#1877F2] hover:bg-[#0C63D4]'
    },
    {
      name: 'Twitter',
      icon: Twitter,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
      color: 'bg-[#1DA1F2] hover:bg-[#0C85D0]'
    },
    {
      name: 'WhatsApp',
      icon: MessageCircle,
      url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`,
      color: 'bg-[#25D366] hover:bg-[#1FAD53]'
    }
  ];

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Link copiado!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="border-t border-b border-gray-200 py-6 my-8">
      <h3 className="font-semibold text-gray-900 mb-4 flex items-center gap-2">
        <Share2 size={20} />
        Compartilhe esta notícia
      </h3>
      
      <div className="flex flex-wrap gap-3">
        {shareLinks.map((link) => (
          <a
            key={link.name}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-flex items-center px-4 py-2 rounded-md text-white ${link.color} transition-colors`}
          >
            <link.icon size={18} className="mr-2" />
            {link.name}
          </a>
        ))}
        
        <button
          onClick={copyToClipboard}
          className="inline-flex items-center px-4 py-2 rounded-md text-white bg-gray-600 hover:bg-gray-700 transition-colors"
        >
          <LinkIcon size={18} className="mr-2" />
          Copiar Link
        </button>
      </div>
    </div>
  );
}