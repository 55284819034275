import React from 'react';
import { SEO } from '../utils/seo';
import EmbedCode from '../components/EmbedCode';

export default function Embed() {
  return (
    <>
      <SEO 
        title="Adicionar em seu Site"
        description="Adicione o player da Rádio Tok em seu site, blog ou jogo. Copie o código de incorporação e personalize conforme sua necessidade."
        keywords="player rádio tok, embed rádio, código incorporação, widget rádio"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Adicionar em seu Site</h1>
            <p className="text-xl text-gray-600">
              Compartilhe a Rádio Tok com seus visitantes
            </p>
          </div>

          <EmbedCode />
        </div>
      </div>
    </>
  );
}