import React from 'react';
import { Link } from 'react-router-dom';
import { Newspaper } from 'lucide-react';
import { useNews } from '../hooks/useNews';

export default function FeaturedNews() {
  const { featuredNews, loading, error } = useNews();

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/2"></div>
          <div className="space-y-3">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="space-y-2">
                <div className="h-40 bg-gray-200 rounded"></div>
                <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-gray-600">
          {error}
        </div>
      </div>
    );
  }

  if (!featuredNews.length) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-6">
        <Newspaper className="text-red-600" size={24} />
        <h2 className="text-2xl font-bold">Notícias em Destaque</h2>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {featuredNews.map((news) => (
          <Link 
            key={news.id} 
            to={`/noticias/${news.id}`}
            className="group"
          >
            <div className="relative aspect-video mb-3 overflow-hidden rounded-lg">
              <img
                src={news.image_url || 'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?w=800'}
                alt={news.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <h3 className="font-semibold text-lg mb-2 group-hover:text-red-600 transition-colors">
              {news.title}
            </h3>
            <p className="text-sm text-gray-600">
              {new Date(news.created_at).toLocaleDateString('pt-BR')}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}