import React, { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabase';
import { ArrowUp, ArrowDown, Minus, Pencil, Image, Plus } from 'lucide-react';

type TopSong = {
  id: string;
  position: number;
  title: string;
  artist: string;
  artist_image: string;
  movement: 'up' | 'down' | 'same';
};

type NewSong = Omit<TopSong, 'id'>;

export default function TopSongs() {
  const [songs, setSongs] = useState<TopSong[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingSong, setEditingSong] = useState<TopSong | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newSong, setNewSong] = useState<NewSong>({
    position: 1,
    title: '',
    artist: '',
    artist_image: '',
    movement: 'same'
  });

  useEffect(() => {
    loadSongs();
  }, []);

  async function loadSongs() {
    const { data, error } = await supabase
      .from('top_songs')
      .select('*')
      .order('position');

    if (error) {
      console.error('Error loading songs:', error);
      return;
    }

    setSongs(data);
    setLoading(false);
  }

  async function updateSong(song: TopSong) {
    const { error } = await supabase
      .from('top_songs')
      .update({
        title: song.title,
        artist: song.artist,
        artist_image: song.artist_image,
        movement: song.movement,
      })
      .eq('id', song.id);

    if (error) {
      console.error('Error updating song:', error);
      return;
    }

    setEditingSong(null);
    loadSongs();
  }

  async function addNewSong() {
    // Adjust positions of existing songs
    const updatedSongs = songs.map(song => ({
      ...song,
      position: song.position >= newSong.position ? song.position + 1 : song.position
    }));

    // Update all positions first
    for (const song of updatedSongs) {
      if (song.position > newSong.position) {
        await supabase
          .from('top_songs')
          .update({ position: song.position })
          .eq('id', song.id);
      }
    }

    // Insert new song
    const { error } = await supabase
      .from('top_songs')
      .insert([newSong]);

    if (error) {
      console.error('Error adding new song:', error);
      return;
    }

    setIsAddingNew(false);
    setNewSong({
      position: 1,
      title: '',
      artist: '',
      artist_image: '',
      movement: 'same'
    });
    loadSongs();
  }

  const availablePositions = Array.from({ length: Math.min(10, songs.length + 1) }, (_, i) => i + 1);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Top 10 Músicas
        </h3>
        {!isAddingNew && songs.length < 10 && (
          <button
            onClick={() => setIsAddingNew(true)}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
          >
            <Plus className="w-4 h-4 mr-2" />
            Adicionar Música
          </button>
        )}
      </div>

      <div className="border-t border-gray-200">
        {isAddingNew && (
          <div className="p-4 bg-gray-50 border-b border-gray-200">
            <h4 className="text-md font-medium text-gray-900 mb-4">Adicionar Nova Música</h4>
            <div className="space-y-3">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Posição
                </label>
                <select
                  value={newSong.position}
                  onChange={(e) => setNewSong({ ...newSong, position: parseInt(e.target.value) })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                >
                  {availablePositions.map((pos) => (
                    <option key={pos} value={pos}>
                      {pos}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Título
                </label>
                <input
                  type="text"
                  value={newSong.title}
                  onChange={(e) => setNewSong({ ...newSong, title: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Artista
                </label>
                <input
                  type="text"
                  value={newSong.artist}
                  onChange={(e) => setNewSong({ ...newSong, artist: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  URL da Imagem do Artista
                </label>
                <input
                  type="url"
                  value={newSong.artist_image}
                  onChange={(e) => setNewSong({ ...newSong, artist_image: e.target.value })}
                  placeholder="https://exemplo.com/imagem.jpg"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Movimento
                </label>
                <select
                  value={newSong.movement}
                  onChange={(e) => setNewSong({ ...newSong, movement: e.target.value as 'up' | 'down' | 'same' })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                >
                  <option value="up">Subiu</option>
                  <option value="down">Desceu</option>
                  <option value="same">Manteve</option>
                </select>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={addNewSong}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                  Adicionar
                </button>
                <button
                  onClick={() => setIsAddingNew(false)}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}

        <ul className="divide-y divide-gray-200">
          {songs.map((song) => (
            <li key={song.id} className="px-4 py-4">
              {editingSong?.id === song.id ? (
                <div className="space-y-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Título
                    </label>
                    <input
                      type="text"
                      value={editingSong.title}
                      onChange={(e) =>
                        setEditingSong({ ...editingSong, title: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Artista
                    </label>
                    <input
                      type="text"
                      value={editingSong.artist}
                      onChange={(e) =>
                        setEditingSong({ ...editingSong, artist: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      URL da Imagem do Artista
                    </label>
                    <input
                      type="url"
                      value={editingSong.artist_image || ''}
                      onChange={(e) =>
                        setEditingSong({ ...editingSong, artist_image: e.target.value })
                      }
                      placeholder="https://exemplo.com/imagem.jpg"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Movimento
                    </label>
                    <select
                      value={editingSong.movement}
                      onChange={(e) =>
                        setEditingSong({
                          ...editingSong,
                          movement: e.target.value as 'up' | 'down' | 'same',
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    >
                      <option value="up">Subiu</option>
                      <option value="down">Desceu</option>
                      <option value="same">Manteve</option>
                    </select>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => updateSong(editingSong)}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                    >
                      Salvar
                    </button>
                    <button
                      onClick={() => setEditingSong(null)}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <span className="text-2xl font-bold text-gray-400 w-8">
                      {song.position}
                    </span>
                    {song.artist_image ? (
                      <img 
                        src={song.artist_image} 
                        alt={song.artist}
                        className="w-12 h-12 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                        <Image className="w-6 h-6 text-gray-400" />
                      </div>
                    )}
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {song.title}
                      </p>
                      <p className="text-sm text-gray-500">{song.artist}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-sm">
                      {song.movement === 'up' && (
                        <ArrowUp className="text-green-500" />
                      )}
                      {song.movement === 'down' && (
                        <ArrowDown className="text-red-500" />
                      )}
                      {song.movement === 'same' && (
                        <Minus className="text-gray-400" />
                      )}
                    </div>
                    <button
                      onClick={() => setEditingSong(song)}
                      className="text-sm text-red-600 hover:text-red-700 flex items-center gap-1"
                    >
                      <Pencil className="w-4 h-4" />
                      Editar
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}