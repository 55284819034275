import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Newspaper } from 'lucide-react';
import { supabase } from '../lib/supabase';

type News = {
  id: string;
  title: string;
  slug: string;
  image_url: string | null;
  created_at: string;
};

type RelatedNewsProps = {
  currentId: string;
};

export default function RelatedNews({ currentId }: RelatedNewsProps) {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadRelatedNews();
  }, [currentId]);

  async function loadRelatedNews() {
    try {
      const { data } = await supabase
        .from('news')
        .select('id, title, slug, image_url, created_at')
        .neq('id', currentId)
        .eq('published', true)
        .order('created_at', { ascending: false })
        .limit(3);

      setNews(data || []);
    } catch (err) {
      console.error('Error loading related news:', err);
    } finally {
      setLoading(false);
    }
  }

  if (loading || news.length === 0) return null;

  return (
    <div className="border-t border-gray-200 pt-8 mt-8">
      <h3 className="font-semibold text-xl text-gray-900 mb-6 flex items-center gap-2">
        <Newspaper size={24} className="text-red-600" />
        Leia também
      </h3>
      
      <div className="grid gap-6 md:grid-cols-3">
        {news.map((item) => (
          <Link 
            key={item.id}
            to={`/noticias/${item.slug}`}
            className="group"
          >
            <div className="relative aspect-video mb-3 overflow-hidden rounded-lg">
              <img
                src={item.image_url || 'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?w=800'}
                alt={item.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </div>
            <h4 className="font-semibold group-hover:text-red-600 transition-colors">
              {item.title}
            </h4>
            <time className="text-sm text-gray-500">
              {new Date(item.created_at).toLocaleDateString('pt-BR')}
            </time>
          </Link>
        ))}
      </div>
    </div>
  );
}