import React, { useEffect, useState } from 'react';
import { Sparkles, History, ThumbsUp, Headphones } from 'lucide-react';
import { supabase } from '../lib/supabase';

type Song = {
  title: string;
  artist: string;
  genre?: string;
};

type UserPreferences = {
  recentlyPlayed: Song[];
  likedArtists: string[];
  likedGenres: string[];
  browserHistory: string[];
  lastVisit: string;
};

export default function SmartRecommendations() {
  const [recommendations, setRecommendations] = useState<Song[]>([]);
  const [loading, setLoading] = useState(true);
  const [userPrefs, setUserPrefs] = useState<UserPreferences | null>(null);

  useEffect(() => {
    analyzeUserBehavior();
    loadRecommendations();
  }, []);

  function analyzeUserBehavior() {
    try {
      // Get all cookies
      const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {} as Record<string, string>);

      // Get browser history related to music (if available)
      const musicKeywords = ['sertanejo', 'música', 'cantores', 'shows', 'festival'];
      const browserHistory = Object.entries(localStorage)
        .filter(([key]) => 
          musicKeywords.some(keyword => key.toLowerCase().includes(keyword))
        )
        .map(([, value]) => value);

      // Analyze user's time zone and local time
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userHour = new Date().getHours();
      const timeOfDay = userHour < 12 ? 'morning' : userHour < 18 ? 'afternoon' : 'evening';

      // Get previously stored preferences
      const storedPrefs = localStorage.getItem('userPreferences');
      const previousPrefs = storedPrefs ? JSON.parse(storedPrefs) : null;

      // Combine all data
      const newPrefs: UserPreferences = {
        recentlyPlayed: previousPrefs?.recentlyPlayed || [],
        likedArtists: previousPrefs?.likedArtists || [],
        likedGenres: ['sertanejo', 'sertanejo universitário'],
        browserHistory,
        lastVisit: new Date().toISOString()
      };

      // Store the combined preferences
      localStorage.setItem('userPreferences', JSON.stringify(newPrefs));
      setUserPrefs(newPrefs);

      // Store key data in cookies for cross-session persistence
      document.cookie = `user_timezone=${userTimeZone}; path=/; max-age=31536000`;
      document.cookie = `time_of_day=${timeOfDay}; path=/; max-age=86400`;
      document.cookie = `liked_artists=${JSON.stringify(newPrefs.likedArtists)}; path=/; max-age=31536000`;

    } catch (error) {
      console.error('Error analyzing user behavior:', error);
    }
  }

  async function loadRecommendations() {
    try {
      // Get top songs from Supabase
      const { data: topSongs } = await supabase
        .from('top_songs')
        .select('title, artist')
        .limit(10);

      if (!topSongs) {
        throw new Error('No songs available');
      }

      // Get user's local time
      const userHour = new Date().getHours();
      
      // Weight factors for different times of day
      let recommendations = topSongs.map(song => ({
        ...song,
        weight: calculateSongWeight(song, userHour)
      }));

      // Sort by weight and take top 6
      recommendations.sort((a, b) => b.weight - a.weight);
      setRecommendations(recommendations.slice(0, 6));

    } catch (error) {
      console.error('Error loading recommendations:', error);
      // Fallback recommendations
      setRecommendations([
        { title: "Erro Gostoso", artist: "Simone Mendes" },
        { title: "Lapada Dela", artist: "Zé Felipe" },
        { title: "Bombonzinho", artist: "Israel & Rodolffo" },
        { title: "Seu Brilho Sumiu", artist: "Israel & Rodolffo" },
        { title: "Narcisista", artist: "Maiara & Maraisa" },
        { title: "Chega e Senta", artist: "John Amplificado" }
      ]);
    } finally {
      setLoading(false);
    }
  }

  function calculateSongWeight(song: Song, hour: number): number {
    let weight = 1;

    // Time-based weighting
    if (hour >= 6 && hour < 12) { // Morning
      weight *= 1.2; // Upbeat songs for morning
    } else if (hour >= 12 && hour < 18) { // Afternoon
      weight *= 1.0; // Balanced mix
    } else { // Evening/Night
      weight *= 0.8; // More relaxed songs
    }

    // User preferences weighting
    if (userPrefs) {
      if (userPrefs.likedArtists.includes(song.artist)) {
        weight *= 1.5;
      }
      if (userPrefs.recentlyPlayed.some(played => played.artist === song.artist)) {
        weight *= 0.7; // Reduce weight for recently played artists
      }
    }

    return weight;
  }

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/2"></div>
          <div className="space-y-3">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="h-12 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center gap-2 mb-6">
        <Sparkles className="text-purple-600" size={24} />
        <h2 className="text-2xl font-bold">Recomendações IA</h2>
      </div>

      <div className="space-y-6">
        {/* Based on History */}
        <div>
          <div className="flex items-center gap-2 mb-3">
            <History className="text-gray-500" size={16} />
            <h3 className="font-semibold text-gray-700">Baseado no seu histórico</h3>
          </div>
          <ul className="space-y-2">
            {recommendations.slice(0, 3).map((song, index) => (
              <li
                key={index}
                className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer flex items-center justify-between group"
              >
                <div className="flex items-center gap-3">
                  <Headphones className="text-gray-400 group-hover:text-purple-600 transition-colors" size={16} />
                  <span>{song.title} - {song.artist}</span>
                </div>
                <button 
                  className="p-1 text-gray-400 hover:text-purple-600 transition-colors"
                  onClick={() => {
                    if (userPrefs && !userPrefs.likedArtists.includes(song.artist)) {
                      const newPrefs = {
                        ...userPrefs,
                        likedArtists: [...userPrefs.likedArtists, song.artist]
                      };
                      localStorage.setItem('userPreferences', JSON.stringify(newPrefs));
                      setUserPrefs(newPrefs);
                      document.cookie = `liked_artists=${JSON.stringify(newPrefs.likedArtists)}; path=/; max-age=31536000`;
                    }
                  }}
                >
                  <ThumbsUp size={16} />
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Trending for You */}
        <div>
          <div className="flex items-center gap-2 mb-3">
            <Sparkles className="text-gray-500" size={16} />
            <h3 className="font-semibold text-gray-700">Tendências para você</h3>
          </div>
          <ul className="space-y-2">
            {recommendations.slice(3, 6).map((song, index) => (
              <li
                key={index}
                className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer flex items-center justify-between group"
              >
                <div className="flex items-center gap-3">
                  <Headphones className="text-gray-400 group-hover:text-purple-600 transition-colors" size={16} />
                  <span>{song.title} - {song.artist}</span>
                </div>
                <button 
                  className="p-1 text-gray-400 hover:text-purple-600 transition-colors"
                  onClick={() => {
                    if (userPrefs && !userPrefs.likedArtists.includes(song.artist)) {
                      const newPrefs = {
                        ...userPrefs,
                        likedArtists: [...userPrefs.likedArtists, song.artist]
                      };
                      localStorage.setItem('userPreferences', JSON.stringify(newPrefs));
                      setUserPrefs(newPrefs);
                      document.cookie = `liked_artists=${JSON.stringify(newPrefs.likedArtists)}; path=/; max-age=31536000`;
                    }
                  }}
                >
                  <ThumbsUp size={16} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}