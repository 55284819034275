import React from 'react';
import { Link } from 'react-router-dom';
import { Radio, Home, Music, MessageCircle, Calendar, AlertCircle } from 'lucide-react';
import { SEO } from '../utils/seo';

export default function NotFound() {
  return (
    <>
      <SEO 
        title="Página Não Encontrada"
        description="A página que você está procurando não foi encontrada. Conheça o novo site da Rádio Tok com design moderno e novas funcionalidades."
        keywords="404, página não encontrada, novo site rádio tok"
      />

      <div className="min-h-[calc(100vh-theme(spacing.20))] bg-gray-50 flex flex-col">
        <div className="flex-grow flex items-center justify-center px-4 py-12">
          <div className="max-w-xl w-full text-center">
            <div className="mb-8">
              <div className="relative mx-auto w-24 h-24 mb-4">
                <div className="absolute inset-0 bg-red-100 rounded-full animate-ping opacity-25"></div>
                <div className="relative bg-brown-800 rounded-full p-6">
                  <AlertCircle className="w-12 h-12 text-white" />
                </div>
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Página Não Encontrada
              </h1>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6 text-left">
                <p className="text-yellow-700">
                  <strong>Aviso:</strong> Nosso site foi completamente redesenhado para melhorar sua experiência.
                  Alguns links antigos podem não funcionar mais.
                </p>
              </div>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md mb-8">
              <h2 className="text-xl font-semibold mb-4">Conheça o Novo Site</h2>
              <div className="grid gap-4 md:grid-cols-2 text-left">
                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-full bg-red-100 flex items-center justify-center flex-shrink-0">
                    <Radio className="w-5 h-5 text-red-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Player Moderno</h3>
                    <p className="text-sm text-gray-600">
                      Novo player com controles intuitivos e melhor qualidade de áudio
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0">
                    <MessageCircle className="w-5 h-5 text-green-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Pedidos via WhatsApp</h3>
                    <p className="text-sm text-gray-600">
                      Peça suas músicas diretamente pelo WhatsApp 24h
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                    <Music className="w-5 h-5 text-blue-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Top 10 Sertanejo</h3>
                    <p className="text-sm text-gray-600">
                      Ranking atualizado com os maiores sucessos
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                    <Calendar className="w-5 h-5 text-purple-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Notícias e Eventos</h3>
                    <p className="text-sm text-gray-600">
                      Fique por dentro do mundo sertanejo
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <nav className="space-y-4">
              <div className="flex flex-wrap justify-center gap-4">
                <Link
                  to="/"
                  className="inline-flex items-center px-6 py-3 bg-brown-800 text-white rounded-lg hover:bg-brown-900 transition-colors"
                >
                  <Home className="w-5 h-5 mr-2" />
                  Página Inicial
                </Link>
                <a
                  href="/#music-request"
                  className="inline-flex items-center px-6 py-3 border-2 border-brown-800 text-brown-800 rounded-lg hover:bg-brown-50 transition-colors"
                >
                  <Music className="w-5 h-5 mr-2" />
                  Pedir Música
                </a>
              </div>

              <div className="flex flex-wrap justify-center gap-3">
                <Link
                  to="/sobre"
                  className="text-gray-600 hover:text-brown-800 text-sm"
                >
                  Sobre Nós
                </Link>
                <span className="text-gray-300">•</span>
                <Link
                  to="/apps"
                  className="text-gray-600 hover:text-brown-800 text-sm"
                >
                  Aplicativos
                </Link>
                <span className="text-gray-300">•</span>
                <Link
                  to="/contato"
                  className="text-gray-600 hover:text-brown-800 text-sm"
                >
                  Contato
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}