import { useState, useEffect } from 'react';
import { useSettings } from './useSettings';
import { getRandomFallbackImage, preloadImage } from '../utils/images';

export function useHeroImage() {
  const [heroImage, setHeroImage] = useState<string>(getRandomFallbackImage('hero'));
  const [loading, setLoading] = useState(true);
  const { settings } = useSettings();

  useEffect(() => {
    let mounted = true;

    async function loadImage() {
      try {
        if (settings?.hero_image) {
          await preloadImage(settings.hero_image);
          if (mounted) {
            setHeroImage(settings.hero_image);
          }
        }
      } catch (error) {
        console.error('Error loading hero image:', error);
        if (mounted) {
          setHeroImage(getRandomFallbackImage('hero'));
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadImage();

    return () => {
      mounted = false;
    };
  }, [settings]);

  return { heroImage, loading };
}