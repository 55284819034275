import React from 'react';
import { SEO } from '../utils/seo';
import { useHeroImage } from '../hooks/useHeroImage';
import { Music } from 'lucide-react';
import MusicRequest from '../components/MusicRequest';
import FeaturedNews from '../components/FeaturedNews';
import TopTen from '../components/TopTen';
import RecentTracks from '../components/RecentTracks';
import SmartRecommendations from '../components/SmartRecommendations';
import AppDownload from '../components/AppDownload';
import EmbedCode from '../components/EmbedCode';

export default function Home() {
  const { heroImage, loading } = useHeroImage();

  return (
    <>
      <SEO 
        title="A Melhor Rádio Sertaneja Online 24h"
        description="Ouça o melhor do sertanejo universitário 24h ao vivo na Rádio Tok. Top hits sertanejos, lançamentos exclusivos e pedidos de música."
        keywords="rádio sertaneja, música sertaneja, sertanejo universitário, rádio online, música ao vivo"
      />

      {/* Hero Section */}
      <div 
        className="relative h-[50vh] bg-cover bg-center flex items-center justify-center"
        style={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroImage})`,
          opacity: loading ? 0 : 1,
          transition: 'opacity 0.5s ease-in-out'
        }}
      >
        <div className="text-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Rádio Tok
          </h1>
          <p className="text-lg md:text-2xl mb-6 font-light">
            O Som do Brasil
          </p>
          <a 
            href="#music-request" 
            className="inline-flex items-center px-6 py-3 border-2 border-white text-base font-medium rounded-full text-white hover:bg-white hover:text-brown-800 transition-colors duration-300"
          >
            <Music className="w-5 h-5 mr-2" />
            Peça sua Música
          </a>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="grid gap-6 lg:grid-cols-12">
          {/* Left Column - Main Content */}
          <div className="lg:col-span-8 space-y-6">
            <MusicRequest />
            <FeaturedNews />
            <SmartRecommendations />
          </div>
          
          {/* Right Column - Sidebar */}
          <div className="lg:col-span-4 space-y-6">
            <div id="ranking">
              <TopTen />
            </div>
            <RecentTracks />
          </div>

          {/* Full Width Sections */}
          <div className="lg:col-span-12 space-y-6">
            <AppDownload />
          </div>
        </div>
      </div>
    </>
  );
}