import React, { useEffect } from 'react';
import { Music } from 'lucide-react';

declare global {
  interface Window {
    CC_streaminfo?: any;
  }
}

export default function StreamInfo() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cast2.hoost.com.br:2199/system/streaminfo.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
        <Music className="text-red-600" size={24} />
      </div>
      <div className="flex flex-col">
        <div className="font-medium">
          <span className="cc_streaminfo" data-type="trackartist" data-username="radios18"></span>
          {" - "}
          <span className="cc_streaminfo" data-type="tracktitle" data-username="radios18"></span>
        </div>
      </div>
    </div>
  );
}