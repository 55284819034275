import React, { useEffect, useState } from 'react';
import { Trash2, Check, X, AlertTriangle, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../../lib/supabase';

type MusicRequest = {
  id: string;
  name: string;
  song: string;
  artist: string;
  message: string | null;
  status: 'pending' | 'approved' | 'rejected';
  created_at: string;
};

const ITEMS_PER_PAGE = 5;

export default function MusicRequests() {
  const [requests, setRequests] = useState<MusicRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [clearingAll, setClearingAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);

  useEffect(() => {
    loadRequests();
  }, [currentPage]);

  async function loadRequests() {
    try {
      // Get total count
      const { count } = await supabase
        .from('music_requests')
        .select('*', { count: 'exact', head: true });

      setTotalRequests(count || 0);

      // Get paginated data
      const { data, error } = await supabase
        .from('music_requests')
        .select('*')
        .order('created_at', { ascending: false })
        .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);

      if (error) throw error;
      setRequests(data || []);
    } catch (err) {
      console.error('Error loading requests:', err);
    } finally {
      setLoading(false);
    }
  }

  async function updateStatus(id: string, status: 'approved' | 'rejected') {
    try {
      const { error } = await supabase
        .from('music_requests')
        .update({ status })
        .eq('id', id);

      if (error) throw error;
      
      // Update local state
      setRequests(prevRequests => 
        prevRequests.map(request => 
          request.id === id ? { ...request, status } : request
        )
      );
    } catch (err) {
      console.error('Error updating status:', err);
    }
  }

  async function deleteRequest(id: string) {
    if (!window.confirm('Tem certeza que deseja excluir este pedido?')) {
      return;
    }

    setDeleting(id);
    try {
      const { error } = await supabase
        .from('music_requests')
        .delete()
        .eq('id', id);

      if (error) throw error;

      // Remove the request from local state and update total
      setRequests(prevRequests => prevRequests.filter(request => request.id !== id));
      setTotalRequests(prev => prev - 1);

      // If page is empty and not first page, go to previous page
      if (requests.length === 1 && currentPage > 1) {
        setCurrentPage(prev => prev - 1);
      }
    } catch (err) {
      console.error('Error deleting request:', err);
      alert('Erro ao excluir o pedido. Tente novamente.');
    } finally {
      setDeleting(null);
    }
  }

  async function clearAllRequests() {
    // Triple confirmation for clearing all requests
    if (!window.confirm('ATENÇÃO: Você está prestes a excluir TODOS os pedidos de música. Esta ação não pode ser desfeita!')) {
      return;
    }
    
    if (!window.confirm('Tem absoluta certeza? Todos os pedidos serão excluídos permanentemente!')) {
      return;
    }

    if (!window.confirm('ÚLTIMA CONFIRMAÇÃO: Você realmente quer excluir todos os pedidos? Esta ação é irreversível!')) {
      return;
    }

    setClearingAll(true);
    try {
      // Delete requests one by one to ensure all are deleted
      for (const request of requests) {
        const { error } = await supabase
          .from('music_requests')
          .delete()
          .eq('id', request.id);
          
        if (error) throw error;
      }

      // Clear local state and reset pagination
      setRequests([]);
      setTotalRequests(0);
      setCurrentPage(1);
      alert('Todos os pedidos foram excluídos com sucesso!');
    } catch (err) {
      console.error('Error clearing all requests:', err);
      alert('Erro ao excluir os pedidos. Tente novamente.');
      // Reload requests to ensure UI is in sync with server
      loadRequests();
    } finally {
      setClearingAll(false);
    }
  }

  const totalPages = Math.ceil(totalRequests / ITEMS_PER_PAGE);

  if (loading) {
    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[...Array(ITEMS_PER_PAGE)].map((_, i) => (
              <div key={i} className="h-20 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Pedidos de Música
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Total de pedidos: {totalRequests}
            </p>
          </div>
          {requests.length > 0 && (
            <button
              onClick={clearAllRequests}
              disabled={clearingAll}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
            >
              <AlertTriangle className="h-4 w-4 mr-2" />
              {clearingAll ? 'Limpando...' : 'Limpar Tudo'}
            </button>
          )}
        </div>
      </div>
      
      <div className="border-t border-gray-200">
        {totalRequests === 0 ? (
          <div className="p-6 text-center text-gray-500">
            Nenhum pedido de música encontrado.
          </div>
        ) : (
          <>
            <ul className="divide-y divide-gray-200">
              {requests.map((request) => (
                <li key={request.id} className="px-4 py-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {request.song} - {request.artist}
                      </p>
                      <p className="text-sm text-gray-500">
                        Pedido por: {request.name}
                      </p>
                      {request.message && (
                        <p className="text-sm text-gray-500 mt-1">
                          Mensagem: {request.message}
                        </p>
                      )}
                      <p className="text-xs text-gray-400 mt-1">
                        {new Date(request.created_at).toLocaleString()}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => updateStatus(request.id, 'approved')}
                        disabled={request.status !== 'pending'}
                        className={`p-2 rounded text-sm font-medium ${
                          request.status === 'approved'
                            ? 'bg-green-100 text-green-800'
                            : request.status === 'pending'
                            ? 'bg-gray-100 text-gray-800 hover:bg-green-100 hover:text-green-800'
                            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        }`}
                        title="Aprovar pedido"
                      >
                        <Check size={16} />
                      </button>
                      <button
                        onClick={() => updateStatus(request.id, 'rejected')}
                        disabled={request.status !== 'pending'}
                        className={`p-2 rounded text-sm font-medium ${
                          request.status === 'rejected'
                            ? 'bg-red-100 text-red-800'
                            : request.status === 'pending'
                            ? 'bg-gray-100 text-gray-800 hover:bg-red-100 hover:text-red-800'
                            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        }`}
                        title="Rejeitar pedido"
                      >
                        <X size={16} />
                      </button>
                      <button
                        onClick={() => deleteRequest(request.id)}
                        disabled={deleting === request.id}
                        className={`p-2 rounded text-sm font-medium bg-gray-100 text-gray-800 hover:bg-red-100 hover:text-red-800 transition-colors ${
                          deleting === request.id ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        title="Excluir pedido"
                      >
                        <Trash2 size={16} className={deleting === request.id ? 'animate-spin' : ''} />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {/* Pagination */}
            <div className="px-4 py-3 border-t border-gray-200 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex-1 flex justify-between sm:hidden">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                  >
                    Anterior
                  </button>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                  >
                    Próxima
                  </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Mostrando <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> até{' '}
                      <span className="font-medium">
                        {Math.min(currentPage * ITEMS_PER_PAGE, totalRequests)}
                      </span>{' '}
                      de <span className="font-medium">{totalRequests}</span> pedidos
                    </p>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Anterior</span>
                        <ChevronLeft className="h-5 w-5" />
                      </button>
                      {[...Array(totalPages)].map((_, i) => (
                        <button
                          key={i + 1}
                          onClick={() => setCurrentPage(i + 1)}
                          className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                            currentPage === i + 1
                              ? 'z-10 bg-red-50 border-red-500 text-red-600'
                              : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {i + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Próxima</span>
                        <ChevronRight className="h-5 w-5" />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}