import { useState, useCallback } from 'react';
import { audioStream } from '../services/audioStream';

export function useAudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [error, setError] = useState<string | null>(null);

  const togglePlay = useCallback(async () => {
    try {
      if (audioStream.isPlaying()) {
        audioStream.pause();
        setIsPlaying(false);
      } else {
        await audioStream.play();
        setIsPlaying(true);
      }
      setError(null);
    } catch (err) {
      setError('Erro ao reproduzir áudio. Tente novamente.');
      setIsPlaying(false);
    }
  }, []);

  const handleVolumeChange = useCallback((newVolume: number) => {
    setVolume(newVolume);
    audioStream.setVolume(newVolume);
  }, []);

  const toggleMute = useCallback(() => {
    setIsMuted(prev => {
      const newState = !prev;
      audioStream.setMuted(newState);
      return newState;
    });
  }, []);

  return {
    isPlaying,
    isMuted,
    volume,
    error,
    togglePlay,
    handleVolumeChange,
    toggleMute
  };
}