import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { SEO } from '../utils/seo';
import SocialShare from '../components/SocialShare';
import RelatedNews from '../components/RelatedNews';

type News = {
  id: string;
  title: string;
  content: string;
  image_url: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created_at: string;
};

export default function NewsDetail() {
  const { id } = useParams<{ id: string }>();
  const [news, setNews] = useState<News | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadNews();
  }, [id]);

  async function loadNews() {
    if (!id) return;

    try {
      const { data, error: supabaseError } = await supabase
        .from('news')
        .select('*')
        .eq('id', id)
        .eq('published', true)
        .single();

      if (supabaseError) throw supabaseError;
      setNews(data);
    } catch (err: any) {
      console.error('Error loading news:', err);
      setError('Notícia não encontrada ou não está disponível.');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
            <div className="h-64 bg-gray-200 rounded"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !news) {
    return (
      <>
        <SEO 
          title="Notícia não encontrada"
          description="A notícia que você está procurando não foi encontrada ou não está mais disponível."
        />
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto text-center">
            <p className="text-gray-600 mb-4">{error}</p>
            <Link
              to="/"
              className="inline-flex items-center text-red-600 hover:text-red-700"
            >
              <ArrowLeft className="mr-2" size={20} />
              Voltar para a Home
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SEO 
        title={news.title}
        description={news.meta_description || news.content.substring(0, 160)}
        keywords={news.meta_keywords || "notícias sertanejo, música sertaneja, rádio tok"}
        image={news.image_url || undefined}
        type="article"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <Link
            to="/"
            className="inline-flex items-center text-gray-600 hover:text-red-600 mb-6"
          >
            <ArrowLeft className="mr-2" size={20} />
            Voltar para a Home
          </Link>

          <article className="bg-white rounded-lg shadow-md overflow-hidden">
            {news.image_url && (
              <img
                src={news.image_url}
                alt={news.title}
                className="w-full h-64 object-cover"
              />
            )}
            
            <div className="p-6">
              <h1 className="text-3xl font-bold mb-4">{news.title}</h1>
              
              <time className="text-gray-500 text-sm block mb-6">
                {new Date(news.created_at).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric'
                })}
              </time>
              
              <div className="prose prose-lg max-w-none">
                {news.content.split('\n').map((paragraph, index) => (
                  <p key={index} className="mb-4">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          </article>

          <SocialShare 
            url={window.location.href} 
            title={news.title} 
          />

          <RelatedNews currentId={news.id} />
        </div>
      </div>
    </>
  );
}