export function generateRadioStationSchema(settings: any) {
  return {
    "@context": "https://schema.org",
    "@type": "RadioStation",
    "name": "Rádio Tok",
    "url": "https://radiotok.com",
    "logo": settings?.site_logo || "https://radiotok.com/radio.svg",
    "image": settings?.hero_image || "https://radiotok.com/og-image.jpg",
    "description": "A melhor rádio sertaneja online 24h com os maiores sucessos do sertanejo universitário.",
    "slogan": "O Som do Brasil",
    "foundingDate": "2009-04-27",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Rua São João, 201 - Vila Dainese",
      "addressLocality": "Santa Bárbara d'Oeste",
      "addressRegion": "SP",
      "postalCode": "13455-115",
      "addressCountry": "BR"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-22.7553",
      "longitude": "-47.3716"
    },
    "sameAs": [
      "https://facebook.com/radiotokoficial",
      "https://instagram.com/radiotokoficial",
      "https://twitter.com/radiotok"
    ],
    "potentialAction": {
      "@type": "ListenAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://cast2.hoost.com.br:20088/stream",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/MobileWebPlatform",
          "http://schema.org/IOSPlatform",
          "http://schema.org/AndroidPlatform"
        ]
      }
    }
  };
}

export function insertSchema(schema: object) {
  const existingSchema = document.querySelector('script[data-schema="radio-station"]');
  if (existingSchema) {
    existingSchema.remove();
  }

  const script = document.createElement('script');
  script.setAttribute('type', 'application/ld+json');
  script.setAttribute('data-schema', 'radio-station');
  script.textContent = JSON.stringify(schema, null, 2);
  document.head.appendChild(script);
}