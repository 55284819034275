import React, { useState } from 'react';
import { Code, Copy, Check } from 'lucide-react';

export default function EmbedCode() {
  const [copied, setCopied] = useState(false);
  
  const embedCode = `<iframe 
  src="https://cast2.hoost.com.br:20088/stream" 
  style="border:none; width:100%; height:150px;" 
  title="Rádio Tok Player"
  allow="autoplay"
></iframe>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(embedCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-8">
      <div className="flex items-center gap-2 mb-6">
        <Code className="text-purple-600" size={24} />
        <h2 className="text-2xl font-bold">Adicionar em seu Site</h2>
      </div>

      <p className="text-gray-600 mb-4">
        Copie o código abaixo para adicionar o player da Rádio Tok em seu site, blog ou jogo:
      </p>

      <div className="relative">
        <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto text-sm">
          <code className="text-gray-800">{embedCode}</code>
        </pre>
        
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 bg-white rounded-md shadow-sm border border-gray-200"
          aria-label={copied ? "Código copiado!" : "Copiar código"}
        >
          {copied ? (
            <Check size={20} className="text-green-500" />
          ) : (
            <Copy size={20} />
          )}
        </button>
      </div>

      <div className="mt-6">
        <h3 className="font-semibold mb-2">Preview:</h3>
        <div className="border rounded-lg p-4 bg-gray-50">
          <iframe 
            src="https://cast2.hoost.com.br:20088/stream" 
            style={{ border: 'none', width: '100%', height: '150px' }}
            title="Rádio Tok Player"
            allow="autoplay"
          />
        </div>
      </div>
    </div>
  );
}