import React from 'react';
import { SEO } from '../utils/seo';
import { Shield } from 'lucide-react';

export default function PrivacyPolicy() {
  return (
    <>
      <SEO 
        title="Política de Privacidade"
        description="Política de Privacidade da Rádio Tok. Saiba como protegemos seus dados e informações pessoais."
        keywords="política de privacidade, termos de uso, privacidade rádio tok"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-brown-100 rounded-full mb-4">
              <Shield className="w-8 h-8 text-brown-800" />
            </div>
            <h1 className="text-4xl font-bold mb-4">Política de Privacidade</h1>
            <p className="text-gray-600">
              Última atualização: 25 de junho de 2024
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-md p-8">
            <div className="prose prose-lg max-w-none">
              <p>
                A sua privacidade é importante para nós. É política do Rádio Tok respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site Rádio Tok, e outros sites que possuímos e operamos.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">Coleta e Uso de Informações</h2>
              <p>
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.
              </p>
              <p>
                Também informamos por que estamos coletando e como será usado. Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">Proteção de Dados</h2>
              <p>
                Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
              </p>
              <p>
                Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">Links Externos</h2>
              <p>
                O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">Publicidade</h2>
              <p>
                O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.
              </p>
              <p>
                Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4">Compromisso do Usuário</h2>
              <p>
                O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Rádio Tok oferece no site e com caráter enunciativo, mas não limitativo:
              </p>
              <ul className="list-disc pl-6 mt-4">
                <li>Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
                <li>Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou contra os direitos humanos;</li>
                <li>Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Rádio Tok, de seus fornecedores ou terceiros.</li>
              </ul>

              <div className="mt-8 p-4 bg-gray-50 rounded-lg">
                <p className="text-sm text-gray-600">
                  Esta política é efetiva a partir de 25 de junho de 2024.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}