import React, { useEffect } from 'react';
import { Users } from 'lucide-react';

export default function Listeners() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cast2.hoost.com.br:2199/system/streaminfo.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-2">
        <Users className="text-brown-800" size={24} />
        <h2 className="text-2xl font-bold">Informações do Stream</h2>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="space-y-4 text-gray-700">
          <p>
            Música atual: <a href="https://cast2.hoost.com.br:2199/tunein/radios18.pls" className="cc_streaminfo text-brown-800 hover:underline" data-type="song" data-username="radios18">Carregando ...</a>
          </p>
          <p>
            Título do stream: <span className="cc_streaminfo" data-type="title" data-username="radios18"></span>
          </p>
          <p>
            Taxa de bits: <span className="cc_streaminfo" data-type="bitrate" data-username="radios18"></span>
          </p>
          <p>
            Ouvintes atuais: <span className="cc_streaminfo" data-type="listeners" data-username="radios18"></span>
          </p>
          <p>
            Máximo de ouvintes: <span className="cc_streaminfo" data-type="maxlisteners" data-username="radios18"></span>
          </p>
          <p>
            Status do servidor: <span className="cc_streaminfo" data-type="server" data-username="radios18"></span>
          </p>
          <p>
            Status do AutoDJ: <span className="cc_streaminfo" data-type="autodj" data-username="radios18"></span>
          </p>
          <p>
            Fonte conectada: <span className="cc_streaminfo" data-type="source" data-username="radios18"></span>
          </p>
          <p>
            Horário da rádio: <span className="cc_streaminfo" data-type="date" data-username="radios18"></span> <span className="cc_streaminfo" data-type="time" data-username="radios18"></span>
          </p>
          <p>
            Playlist atual: <span className="cc_streaminfo" data-type="trackplaylist" data-username="radios18"></span>
          </p>
          <p>
            Detalhes da faixa: <span className="cc_streaminfo" data-type="trackartist" data-username="radios18"></span> - <span className="cc_streaminfo" data-type="tracktitle" data-username="radios18"></span> - <span className="cc_streaminfo" data-type="trackalbum" data-username="radios18"></span>
          </p>
          <p>
            Metadados brutos: <span className="cc_streaminfo" data-type="rawmeta" data-username="radios18"></span>
          </p>
          <div className="mt-6">
            <p className="mb-2">Imagem do álbum:</p>
            <img className="cc_streaminfo max-w-xs rounded-lg shadow-md" data-type="trackimageurl" data-username="radios18" alt="Capa do álbum" />
            <a href="#" className="cc_streaminfo block mt-2 text-brown-800 hover:underline" data-type="trackbuyurl" data-username="radios18">Comprar este álbum</a>
          </div>
        </div>
      </div>
    </div>
  );
}