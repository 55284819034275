import React from 'react';
import { Link } from 'react-router-dom';
import { Lock } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import SocialLinks from './SocialLinks';

export default function Footer() {
  const { user } = useAuth();

  return (
    <footer className="bg-gray-900 text-white py-8 mb-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
          <div className="text-center md:text-left">
            <p className="text-lg font-semibold">Rádio Tok – O SOM DO BRASIL</p>
            <p className="text-sm text-gray-400">© Since – 27/04/2009</p>
          </div>
          
          <div className="flex flex-col items-center gap-4">
            <SocialLinks />
            {user ? (
              <Link 
                to="/admin/music-requests" 
                className="text-gray-400 hover:text-red-500 transition-colors flex items-center gap-1 text-sm"
              >
                <Lock size={14} />
                <span>Painel Admin</span>
              </Link>
            ) : (
              <Link 
                to="/admin/login" 
                className="text-gray-400 hover:text-red-500 transition-colors flex items-center gap-1 text-sm"
              >
                <Lock size={14} />
                <span>Login Admin</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}