import React, { useEffect, useState } from 'react';
import { Trophy, ArrowUp, ArrowDown, Minus, Image } from 'lucide-react';
import { supabase } from '../lib/supabase';

type TopSong = {
  position: number;
  title: string;
  artist: string;
  artist_image: string;
  movement: 'up' | 'down' | 'same';
};

export default function TopTen() {
  const [songs, setSongs] = useState<TopSong[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadSongs();
  }, []);

  async function loadSongs() {
    try {
      const { data, error } = await supabase
        .from('top_songs')
        .select('*')
        .order('position');

      if (error) throw error;

      setSongs(data || []);
    } catch (err: any) {
      console.error('Error loading songs:', err);
      setError('Não foi possível carregar o Top 10 no momento.');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 h-full">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-48"></div>
          <div className="space-y-3">
            {[...Array(10)].map((_, i) => (
              <div key={i} className="flex items-center gap-4">
                <div className="w-8 h-8 bg-gray-200 rounded"></div>
                <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-gray-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 h-full">
      <div className="flex items-center gap-2 mb-6">
        <Trophy className="text-yellow-500" size={24} />
        <h2 className="text-2xl font-bold">Top 10 Músicas</h2>
      </div>

      <div className="grid gap-4">
        {songs.map((song) => (
          <div 
            key={song.position} 
            className="flex items-center gap-4 p-3 hover:bg-gray-50 rounded-lg transition-colors"
          >
            <span className="text-2xl font-bold text-gray-400 w-8">
              {song.position}
            </span>
            {song.artist_image ? (
              <img 
                src={song.artist_image} 
                alt={song.artist}
                className="w-12 h-12 rounded-full object-cover"
              />
            ) : (
              <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                <Image className="w-6 h-6 text-gray-400" />
              </div>
            )}
            <div className="flex-1 min-w-0">
              <h3 className="font-semibold truncate">{song.title}</h3>
              <p className="text-gray-600 text-sm truncate">{song.artist}</p>
            </div>
            <div className="text-sm flex-shrink-0">
              {song.movement === 'up' && <ArrowUp className="text-green-500" />}
              {song.movement === 'down' && <ArrowDown className="text-red-500" />}
              {song.movement === 'same' && <Minus className="text-gray-400" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}