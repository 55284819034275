import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSchema } from './hooks/useSchema';
import Navbar from './components/Navbar';
import AudioPlayer from './components/AudioPlayer';
import Footer from './components/Footer';
import AIAssistant from './components/AIAssistant';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Apps from './pages/Apps';
import Embed from './pages/Embed';
import NotFound from './pages/NotFound';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import MusicRequests from './pages/admin/MusicRequests';
import TopSongs from './pages/admin/TopSongs';
import News from './pages/admin/News';
import Listeners from './pages/admin/Listeners';
import AdminAbout from './pages/admin/About';
import Settings from './pages/admin/Settings';
import NewsDetail from './pages/NewsDetail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AdminRoute from './components/AdminRoute';

export default function App() {
  // Initialize schema
  useSchema();

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Navbar />
      
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/embed" element={<Embed />} />
          <Route path="/noticias/:id" element={<NewsDetail />} />
          <Route path="/privacidade" element={<PrivacyPolicy />} />
          
          {/* Admin Routes */}
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin" element={<Navigate to="/admin/music-requests" replace />} />
          <Route path="/admin" element={
            <AdminRoute>
              <Dashboard />
            </AdminRoute>
          }>
            <Route path="music-requests" element={<MusicRequests />} />
            <Route path="top-songs" element={<TopSongs />} />
            <Route path="news" element={<News />} />
            <Route path="listeners" element={<Listeners />} />
            <Route path="about" element={<AdminAbout />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          
          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>

      <Footer />
      <AudioPlayer />
      <AIAssistant />
    </div>
  );
}