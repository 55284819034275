import React from 'react';
import { SEO } from '../utils/seo';
import { Radio, Calendar, Users } from 'lucide-react';

export default function About() {
  return (
    <>
      <SEO 
        title="Sobre Nós"
        description="Conheça a história da Rádio Tok, a voz do sertanejo universitário no Brasil desde 2009. Descubra nossa missão e compromisso com a música sertaneja."
        keywords="história rádio tok, rádio sertaneja, sobre nós, música sertaneja, rádio online"
      />

      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Nossa História</h1>
            <p className="text-xl text-gray-600">
              Desde 2009 levando o melhor do sertanejo para todo o Brasil
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-2 mb-12">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex items-center gap-3 mb-4">
                <Calendar className="text-red-600" size={24} />
                <h2 className="text-xl font-semibold">Nossa Trajetória</h2>
              </div>
              <p className="text-gray-600">
                Fundada em 27 de abril de 2009, a Rádio Tok nasceu com a missão de ser a voz do sertanejo universitário na internet. Começamos como uma pequena web rádio e hoje alcançamos milhares de ouvintes em todo o Brasil.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex items-center gap-3 mb-4">
                <Users className="text-blue-600" size={24} />
                <h2 className="text-xl font-semibold">Nossa Equipe</h2>
              </div>
              <p className="text-gray-600">
                Contamos com uma equipe apaixonada por música sertaneja, formada por DJs, produtores e comunicadores comprometidos em trazer o melhor conteúdo 24 horas por dia.
              </p>
            </div>
          </div>

          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Faça Parte da Nossa História</h2>
            <p className="text-gray-600 mb-6">
              Junte-se a milhares de ouvintes que fazem da Rádio Tok a sua companhia diária.
            </p>
            <a 
              href="/#music-request" 
              className="inline-flex items-center px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
            >
              <Radio className="mr-2" size={20} />
              Peça sua Música
            </a>
          </div>
        </div>
      </div>
    </>
  );
}